h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ff2800;
  justify-content: center;
  text-align: justify;
}
.terms_body {
  background: black !important;
}
