.review_form {
  background: black;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.review_text {
  overflow: visible;
  min-width: 500px;
  margin-bottom: 15px;
}

.submit_container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.btn_submit {
  background: #ffffff;
  color: #ff1414;
  margin-top: 10px;
}
.rate {
  margin-bottom: 0;
}
.rating_title h3 {
  text-align: center;
  font-weight: 500;
  margin-bottom: 0;
}

.rating_title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}
.cancel_img {
  position: absolute;
  right: 0;
}

.btn_close {
  background: #ffffff;
  color: black;
  margin-top: 10px;
  margin-right: 15px;
}

.cancel_img img {
  width: 25px;
  filter: invert(1);
}

@media (max-width: 545px) {
  .review_text {
    min-width: auto;
  }
  .review .container {
    width: 90%;
  }
}
