@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Regular-1.otf");
  font-weight: 500;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Bold.otf");
  font-weight: 600;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Extrabold.otf");
  font-weight: 700;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Black.otf");
  font-weight: 800;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Thin.otf");
  font-weight: 300;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Alt Light.otf");
  font-weight: 200;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Alt Thin.otf");
  font-weight: 100;
}
@font-face {
  font-family: mplus;
  src: url("../fonts/mplus-2p-thin.ttf");
}
@font-face {
  font-family: Avalon_Bold;
  src: url("../fonts/Avalon_Bold.ttf");
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: proxima-nova;
  background: black;
}
.upload {
  border-radius: 4px;
}
.tabcontent {
  display: none;
  margin-top: 25px;
  color: #ffffff;
}
.tabcontent.active {
  display: block;
}
.tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main {
  background-color: #000000;
}
.tab > button {
  color: #ffffff;
  background: none;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.8px;
  padding: 0;
  border-radius: 50%;
  background: #000000;
  height: 130px;
  width: 130px;
  outline: none;
}
button.tablinks.active {
  border: 2px solid #363636;
}
.form_container {
  align-items: flex-end;
  width: 100%;
}
.form_container > div {
  flex: 1;
}
.form_detail input {
  width: 100%;
  padding: 5px 10px;
}
.form_detail label {
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 1.8px;
  font-size: 14px;
}
.form_detail:not(:first-child) {
  margin-top: 10px;
}
.form_container > div:first-child {
  margin-right: 25px;
}
.upload {
  width: 500px;
  height: 195px;
  border: 4px solid #fff;
  position: relative;
}
.upload p {
  width: 100%;
  height: 100%;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.8px;
  background: #ffffff;
  font-weight: 600;
}
.upload input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
}
button.create_ac {
  width: 50%;
}
button.create_ac {
  width: 50%;
  font-size: 20px;
  font-weight: 900;
  padding: 5px 10px;
  border: 0;
  font-family: proxima-nova !important;
}
.privacy {
  margin-top: 25px;
  text-align: center;
  color: #ffffff;
}
.privacy span {
  text-decoration: underline;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.choose_profile_pic {
  text-align: center;
  margin-top: 20px;
  border: 0;
  padding: 5px;
  font-weight: 500;
  border-radius: 4px;
}

.upload img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 999px) {
  .form_container {
    align-items: flex-start;
    flex-direction: column;
  }
  .form_container > div {
    width: 100%;
    margin-right: 0 !important;
  }
  .upload {
    width: 100%;
  }
  .form_right_container {
    margin-top: 25px;
  }
  .tab {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  button.create_ac {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .form_right_container {
    flex-direction: column-reverse;
    display: flex;
    margin-top: 0px;
  }
  .upload {
    margin-top: 20px;
  }
  .tab_in_mobile > button {
    height: 120px;
    width: 120px;
  }
  .tab_in_mobile {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
