@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Regular-1.otf");
  font-weight: 500;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Bold.otf");
  font-weight: 600;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Extrabold.otf");
  font-weight: 700;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Black.otf");
  font-weight: 800;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Thin.otf");
  font-weight: 300;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Alt Light.otf");
  font-weight: 200;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Alt Thin.otf");
  font-weight: 100;
}
@font-face {
  font-family: mplus;
  src: url("../fonts/mplus-2p-thin.ttf");
}
@font-face {
  font-family: Avalon_Bold;
  src: url("../fonts/Avalon_Bold.ttf");
}
.fan_container {
  margin: 0 auto !important;
  padding: 10px 0;
  box-sizing: border-box;
  font-family: proxima-nova;
  color: #ffffff;
  min-height: 100vh;
  background: url("../images/background_black.jpg") !important;
  /* background-size: cover ; */
  background-size: 100vw auto !important;
  background-position: center !important;
}
.form_container {
  width: 70%;
  margin: auto;
}
.draggableImg {
  transition: 2s;
}
header {
  justify-content: center;
}
.links ul {
  padding: 0;
  list-style-type: none;
}
.logo img {
  height: 130px;
  object-fit: contain;
}
.links ul a {
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
}
.links {
  justify-content: center;
  display: flex;
}
header > div {
  flex: 1;
}
.links ul {
  padding: 0;
  list-style-type: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.logo {
  text-align: center;
}
.links ul li {
  letter-spacing: 1.8px;
}
.hero_text {
  font-size: 14px;
  margin-top: 5px;
  letter-spacing: 1.8px;
}
.tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.tab > div {
  display: flex;
  justify-content: center;
  flex: 1;
}
.tab > div:not(:last-child) {
  margin-right: 10px;
}
button.meet_greet_tablinks {
  background-image: url("../images/button_bg.png");
  padding: 25px;
  background-color: transparent;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  height: 120px;
  width: 120px;
  border: 0;
  background-size: contain;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1.8px;
  font-weight: 600;
  outline: none;
}
button.tablinks {
  background-image: url("../images/button_bg.png");
  /* padding: 25px; */
  background-color: transparent;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
  width: 100px;
  border: 0;
  background-size: contain;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1.8px;
  font-weight: 800;
  outline: none;
}
button.tablinks_responsive {
  background-image: url("../images/button_bg.png");
  padding: 25px;
  background-color: transparent;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  height: 120px;
  width: 120px;
  border: 0;
  background-size: contain;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1.8px;
  font-weight: 600;
  outline: none;
}
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #3498db;
  margin: auto;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.adminPaginate {
  display: flex;
  justify-content: flex-end;
}
.adminPaginate .active {
  color: black !important;
  /* padding: 10px; */
  background: #4a5568;
}
.adminPaginate li {
  list-style-type: none;
  cursor: pointer;
  color: #4a5568;
  padding: 8px 12px;
  border-top: 1px solid #4a5568;
  border-left: 1px solid #4a5568;
  border-bottom: 1px solid #4a5568;
  font-weight: 400;
  font-size: 12px;
}
.adminPaginate li:last-child {
  border-right: 1px solid #4a5568;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fan_image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 30px;
}
.activeSubTab {
  border-bottom: 1.75px solid #ffffff !important;
}
button.tablinks.active {
  box-shadow: 0 0 14px 2px #ff0000;
}
.category_empty {
  display: flex;
  background: #333333 !important;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
  justify-content: flex-end;
  border: 0;
  padding: 5px 10px;
  width: 100%;
}
.category_empty input::placeholder {
  padding-left: 3px;
  color: black;
}
.category_empty input {
  border: 2px solid #000000 !important;
  padding: 5px 10px;
  outline: none;
}
.category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #333333 !important;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -3rem;
  margin-right: -3rem;
}
.category_video {
  border: "solid 1px red";
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -3rem;
  margin-right: -3rem;
}
.category > div {
  flex: 1;
  text-align: center;
}
.category_video > div {
  flex: 1;
  text-align: center;
}
.cats_content a {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
}
.cats_content video {
  width: 100%;
}
.main_cat .cats_content video {
  width: 100%;
}
.main_cat video {
  width: 100%;
}
.vids {
  padding: 0 10px;
}
.main_cat:not(:last-child) {
  margin-right: 10px;
}
.main_cat video {
  width: 170px;
  height: 170px;
  object-fit: cover;
  border-radius: 50%;
  outline: none;
}
.profile_images img {
  width: 170px;
  height: 170px;
  object-fit: cover;
  border-radius: 50%;
  outline: none;
}
.tabcontent.active {
  display: block;
}
.tabcontent {
  display: none;
}
.main_links {
  justify-content: center;
}
.down_links {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.down_links img {
  width: 50px;
  object-fit: contain;
}
.down_links:not(:last-child) {
  margin-right: 30px;
}
.down_links div {
  color: #666a76;
}
.main_links {
  justify-content: center;
  margin: 20px 0;
}
.more_list {
  color: white;
  margin: 8px;
}
.down_links li {
  cursor: pointer;
  color: white;
}
.down_links .dropdown-item.active,
.down_links .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: none !important;
}
.down_links .dropdown-item:focus,
.down_links .dropdown-item:hover {
  color: white;
  text-decoration: underline !important;
  border: none;
  background: none !important;
}
.menu_item {
  padding-left: 0px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
  margin-top: 5px;
  margin-right: 4px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1dbe73;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1dbe73;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .form_container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .main_cat video,
  .profile_images img {
    width: 140px;
    height: 140px;
  }
}

@media (max-width: 545px) {
  .tabcontent.active {
    width: 90%;
    margin: auto;
  }
  .vids {
    flex-wrap: wrap;
  }
  .category {
    margin-left: 0;
    margin-right: 0;
  }
  .main_cat:not(:last-child) {
    margin-right: 0;
  }
  button.tablinks {
    padding: 0;
    height: 45px;
    width: 45px;
    font-size: 8px;
  }
  button.meet_greet_tablinks {
    padding: 0;
    height: 60px;
    width: 60px;
    font-size: 10px;
    font-weight: 600;
  }
  .fan_image {
    margin: 0 10px !important;
  }
  .fan_image img {
    width: 80px !important;
    height: 80px !important;
  }
  .fan_image {
    font-size: 12px;
  }
  .adminPaginate {
    justify-content: center;
    padding-left: 0;
  }
  button.tablinks_responsive {
    padding: 0 !important;
    height: 60px;
    width: 60px;
    font-size: 10px;
    font-weight: 600;
  }
  .category_video.vids {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .main_cat video,
  .profile_images image {
    width: 120px;
    height: 120px;
  }
  .fan_image {
    order: 3;
  }
  .tab1 {
    order: 1;
    display: inline-block !important;
    width: 50%;
  }
  .tab2 {
    order: 3;
    display: inline-block !important;
    width: 50%;
    float: right;
  }
  .tab3 {
    order: 4;
    display: inline-block !important;
    width: 50%;
  }
  .tab4 {
    order: 5;
    display: inline-block !important;
    width: 50%;
    float: right;
  }
  .tab {
    display: flex !important;
    flex-wrap: nowrap !important;
  }
  .tab > div:not(:last-child) {
    margin-right: 0;
  }
  .fan_image img {
    width: 80%;
    height: 60px;
    object-fit: cover;
    border-radius: 10px;
  }
  .tab > div {
    text-align: center;
  }
}
