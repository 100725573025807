.forgot_form_container {
  width: 70% !important;
  margin: auto;
  background-color: #000000;
}
.forgot_button button {
  color: #ff2800;
  border: 0;
  background: #2d2d2d;
  outline: none;
  letter-spacing: 1.2px;
  font-family: Avalon_Bold;
}
.btn_submit:hover {
  color: #ff2800;
}
.form_detail input {
  width: 100%;
  padding: 5px 10px;
}
.form_detail label {
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 1.8px;
  font-size: 14px;
}
.form_detail:not(:first-child) {
  margin-top: 10px;
}
@media (max-width: 768px) {
  .forgot_form_container {
    width: 100%;
  }
}
