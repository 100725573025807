@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Regular-1.otf");
  font-weight: 500;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Bold.otf");
  font-weight: 600;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Extrabold.otf");
  font-weight: 700;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Black.otf");
  font-weight: 800;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Thin.otf");
  font-weight: 300;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Alt Light.otf");
  font-weight: 200;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Alt Thin.otf");
  font-weight: 100;
}
@font-face {
  font-family: mplus;
  src: url("../fonts/mplus-2p-thin.ttf");
}
@font-face {
  font-family: Avalon_Bold;
  src: url("../fonts/Avalon_Bold.ttf");
}

.MainwrapperTicket {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: proxima-nova;
  color: #ffffff !important;
  background-color: #000000;
  background-size: cover;
  background-position: center;
}
.main_reciept_container {
  min-height: 500px;
  max-width: 768px;
  margin: auto;
  padding: 8px;
}
.background_image img {
  width: 100%;
  height: 100%;
  /*border: 12px solid transparent;
    border-image: url(images/border_receipt.png) 20;
    border-image-width: 12px;*/
  position: absolute;
  top: 0;
  left: 0;
}
.main_container {
  padding: 10px 30px 5px;
  position: relative;
  z-index: 1000;
}
button.reciept_button {
  padding: 3px 115px;
  border: 0;
  background: #464b4d;
  color: white;
  font-weight: 900;
  letter-spacing: 3px;
  border-radius: 35px;
  outline: none;
}
.table_down {
  width: 100%;
  justify-content: space-between;
  padding: 1px 54px;
  background: #464b4d;
  border-radius: 25px;
}
.table_middle {
  background: none;
}
.table_down > div {
  flex: 1;
  text-align: center;
}
.thanks {
  font-size: 24px;
}
.fan_image img {
  width: 150px;
  height: 150px;
}
button.tablinks.active {
  border: 2px solid #363636;
}
.paid_image > img {
  width: 120px;
}
