body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: proxima-nova;
  color: #ffffff;
  /* background-image: url("../images/JL-GO-LIVE.jpg") !important; */
  background-size: 100vw auto !important;
  background-repeat: no-repeat !important;
  background-position: initial !important;
  background-origin: content-box !important;
}
#performerId {
  width: 100%;
  height: 210px;
  border: solid 1px red;
}
#orb_publish {
  width: 100%;
  height: 210px;
  border: solid 1px red;
}
.main_ORB_section {
  color: #4d4d4d;
}
.main_ORB_section > div {
  flex: 1;
}
.ORB_live_container {
  width: 100%;
  height: 212px !important;
  object-fit: cover;
  display: flex;
}
.ORB_logo {
  text-align: center;
}
.lights {
  border: 3px solid #484848;
  display: inline-block;
}
.lights div {
  height: 13px;
  width: 10px;
  display: block;
}
.lights div:not(:last-child) {
  border-bottom: 1px solid #060606;
}
.lights div {
  height: 17px;
  width: 10px;
}
.ORB_tips_info {
  justify-content: center;
}
.ORB_tips_info.d-flex {
  display: flex;
  justify-content: space-around;
  font-size: 18px;
}
.one_light {
  background: #00ff81;
}
.two_light {
  background: #00ff8e;
}
.three_light {
  background: #00ff9e;
}
.four_light {
  background: #00ffb1;
}
.five_light {
  background: #00ffc1;
}
.six_light {
  background: #94fff4;
}
.seven_light {
  background: #b5fffb;
}
.eight_light {
  background: #cffeff;
}
.nine_light {
  background: #e8fcff;
}
.ten_light {
  background: #ffffff;
}
.value_content {
  flex: 1;
  background: #4d4d4d;
  color: #00ff81;
  text-align: center;
  box-shadow: 0 0 2px 1px #969696;
  border: 0;
}
.value_name {
  flex: 2;
}
.value_container {
  display: flex;
  align-items: center;
}
.values {
  min-width: 200px;
}
.value_container:not(:last-child) {
  margin-bottom: 15px;
}
.ORB_video_live {
  display: block;
  /* width: 100%; */
}
.FAN_ORB_video_live {
  display: block;
  /* width: 100%; */
  height: 212px !important;
}
.ORB_video_live div {
  display: block;
  background: #242529;
  width: 100%;
  box-shadow: inset 3px 5px 5px #3a3a3a;
  border-radius: 0;
}
.FAN_ORB_video_live div {
  display: block;
  background: #242529;
  width: 100%;
  height: 212px !important;
  box-shadow: inset 3px 5px 5px #3a3a3a;
  border-radius: 0;
}
.ORB_video_live:after {
  /* content: "live!"; */
  position: absolute;
  bottom: 10px;
  display: block;
  text-align: center;
  left: 0;
  width: 100%;
  color: #138236;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  /* background: linear-gradient(45deg, #0000004f, transparent); */
}
.FAN_ORB_video_live:after {
  /* content: "live!"; */
  position: absolute;
  bottom: 10px;
  display: block;
  text-align: center;
  left: 0;
  width: 100%;
  color: #138236;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  /* background: linear-gradient(45deg, #0000004f, transparent); */
}
.title_tips {
  text-transform: uppercase;
}
.ORB_videos_container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding: 0px;
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px; */
}
/* .ORB_videos_container {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr 1fr 1fr;
  grid-gap: 20px;
} */
.ORB_videos_container img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border-radius: 50%;
}
.r_image {
  position: relative;
  z-index: 9;
}
.r_image img {
  /* width: 100%; */
  margin: 20px;
}
.ORB_main_cat {
  /* text-align: center;
  padding: 0px;
  
  flex-direction: row;
  margin-bottom: 20px; */
  display: flex;
  margin: 20px;
  /* width: 100%;
  height: 100%; */
}
.fan_ORB_main_cat {
  display: flex;
  padding: 20px;
  border-radius: 50%;
}
.ORB_link img {
  object-fit: none;
  height: 55px;
  width: 55px;
  padding: 5px;
  background-color: #424242;
  border-radius: 50%;
}
.ORB_link p {
  color: #9399a9;
  margin-top: 5px;
  text-align: center;
}
.ORB_link {
  align-items: center;
  min-width: 70px;
}
.ORB_links > a:hover {
  text-decoration: none;
}
.ORB_links > a:not(:last-child) {
  margin-right: 5px;
}
.progress-bar {
  background-color: gray;
}
.center_main {
  width: 90px;
  height: 90px;
  border: solid 1px red;
  border-radius: 50%;
}
/* #user-remote-playerlist {
  position: relative;
} */
.value_container > p {
  margin-bottom: 0;
}
.break_display_text {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#fan-remote-playerlist .agora_video_player {
  border-radius: 50% !important;
  position: relative !important;
  /* width: 130px;
  height: 130px; */
}

#other-fan-remote .agora_video_player {
  border-radius: 50% !important;
  width: 128px !important;
  height: 128px !important;
}

#other-fan-remote1 .agora_video_player {
  border-radius: 50% !important;
  width: 128px !important;
  height: 128px !important;
}
.fan_ORB_main_small_video .agora_video_player {
  border-radius: 50% !important;
  width: 153px !important;
  height: 153px !important;
}
.fan_ORB_main_small_video2 .agora_video_player {
  border-radius: 50% !important;
  width: 112px !important;
  height: 115px !important;
}
.fan_ORB_main_big_video .agora_video_player {
  border-radius: 50% !important;
  width: 100% !important;
  height: 400 !important;
}

#user-remote-playerlist .agora_video_player {
  border-radius: 50% !important;
  position: relative !important;
}

.ORB_main_cat > div {
  /* display: flex;
  flex-direction: row; */
  margin: 20px;
  width: 130px !important;
  height: 130px !important;
  background: none !important;
}

.reviewFan {
  position: absolute;
  top: 300px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  z-index: 9;
}
.reviewFan .container {
  width: auto;
  padding: 50px 25px;
  background: #000000;
  border: 2px solid #ffffff;
}
.fan_image_ORB img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 30px;
}
.rimage_style {
  position: relative;
  top: -11px;
  left: -1px;
}
.orb_fan_placeholder {
}

@media (max-width: 991px) {
  .ORB_videos_container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
  }
  .value_name {
    flex: 1.5;
  }
  .tips {
    margin-left: 10px;
    margin-right: 10px;
  }
  .ORB_logo img {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .ORB_videos_container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  .ORB_logo img {
    width: 145px;
    object-fit: contain;
  }
  .values {
    min-width: 120px;
  }
  .value_container {
    flex-direction: column;
  }
  .ORB_logo {
    order: 1;
    width: 50%;
    display: inline-flex !important;
  }
  .ORB_live_container {
    order: 2;
    width: 50%;
    display: inline-flex !important;
  }
  .ORB_tips_info {
    order: 3;
  }
}

@media (max-width: 540px) {
  .main_ORB_section > div {
    width: 50%;
  }
  .main_ORB_section {
    flex-wrap: wrap;
  }
  .ORB_video_live {
    min-width: 150px;
  }
  .ORB_videos_container {
    grid-template-columns: 1fr 1fr;
  }
  .ORB_links {
    flex-wrap: wrap;
    justify-content: start !important;
  }
  .value_container {
    flex-direction: row;
  }
  .ORB_tips_info {
    margin-top: 25px;
  }
}
