.deactivate button {
  color: #ff2800;
  border: 0;
  background: #2d2d2d;
  outline: none;
  letter-spacing: 1.2px;
  font-family: Avalon_Bold;
}
.btn_submit:hover {
  color: #ff2800;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
.form_detail input {
  width: 100%;
  padding: 5px 10px;
}
.form_detail textarea {
  width: 100%;
  padding: 5px 10px;
}
.go_live {
  color: #ff2800;
  font-size: 18px;
  font-family: "Avalon_Bold";
  cursor: pointer;
}
.upload {
  background-size: contain !important;
}

@media (max-width: 767px) {
  .upload_profile {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
