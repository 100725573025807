.edit_image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
}
.edit_image {
  display: flex;
  flex-direction: column;
}
label.choose_profile_pic {
  text-align: center;
  margin-top: 20px;
  border: 0;
  background-color: #ff921d4f;
  padding: 5px;
  font-weight: 500;
  border-radius: 4px;
}
.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
.edit_image input[type="file"] {
  display: none;
}
