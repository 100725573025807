@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Regular-1.otf");
  font-weight: 500;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Bold.otf");
  font-weight: 600;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Extrabold.otf");
  font-weight: 700;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Black.otf");
  font-weight: 800;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Thin.otf");
  font-weight: 300;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Alt Light.otf");
  font-weight: 200;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Alt Thin.otf");
  font-weight: 100;
}
@font-face {
  font-family: mplus;
  src: url("../fonts/mplus-2p-thin.ttf");
}
@font-face {
  font-family: Avalon_Bold;
  src: url("../fonts/Avalon_Bold.ttf");
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #000000;
  color: #ffffff;
  font-family: Avalon_Bold;
}
header {
  display: flex;
  /* flex-direction: column; */
  /* align-items: flex-end; */
  justify-content: space-between !important;
}
a {
  color: #ffffff;
  transition: 0.2s ease;
  opacity: 1;
}
a:hover {
  color: #ffffff !important;
  opacity: 0.7;
  text-decoration: none;
}
header a {
  padding: 0 10px;
  padding-right: 0;
  color: #6d6d6d;
  text-transform: capitalize;
}
a.login {
  padding-right: 10px;
  line-height: 1;
}
header div:last-child {
  padding-top: 5px;
}
.main_sec > div {
  flex: 1;
}
.main_sec {
  display: flex;
  align-items: flex-end;
}
.custom_main_sec {
  max-width: 1140px;
  margin: 0 auto;
}
.cust_home_page {
  max-width: 100%;
  padding: 0 !important;
}
.cust_home_page header {
  padding: 0 10px;
}
.links {
  justify-content: center;
  display: flex;
}
.links ul {
  padding: 0;
  list-style-type: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 0;
}
.links ul li {
  letter-spacing: 1.8px;
}
.logo {
  text-align: center;
}
.links ul a {
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
}
.links li:not(:last-child) {
  margin-bottom: 5px;
}
.links ul a {
  color: #6d6d6d;
  font-size: 16px;
  text-transform: uppercase;
}
.hero_text.text-center {
  font-weight: 300 !important;
  font-family: proxima-nova;
  margin-top: 15px;
  letter-spacing: 1.8px;
  font-size: 20px;
  margin-bottom: 18px !important;
}
.videos_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
video {
  width: 100%;
  height: 320px;
  object-fit: cover;
  outline: none;
}
.video_content {
  display: flex;
  position: relative;
  z-index: 3;
}
.hero_button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  margin: auto;
  z-index: 9;
  /* background-image: radial-gradient(circle, #000000e8, #00000000, #00000000); */
}
.float_button {
  height: 150px !important;
  width: 150px !important;
  /* border: 15px solid #343434 !important; */
  /* box-shadow: 2px -2px 107px 200px #000; */
}
.float_button:after {
  content: "music";
  position: absolute;
  z-index: 2;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 1.8px;
  font-family: proxima-nova;
  opacity: 1;
  font-weight: 400;
}
.food .float_button:after {
  content: "food";
}
.style .float_button:after {
  content: "style";
}
.body .float_button:after {
  content: "body";
}

.float_button {
  height: 100px;
  width: 100px;
  border: 10px solid #343434;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 104px;
  font-family: "Kodchasan", sans-serif;
  font-weight: 900;
  color: #343434;
  opacity: 0.8;
  position: relative;
  z-index: 4;
}
.videos_container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0000002e;
}

a.login:last-child {
  padding-right: 0;
}
a.login:first-child {
  border-right: 1px solid;
}
.dropdown-menu {
  padding: 0;
}
.menu_item {
  padding-left: 0px;
}
.menu_item i {
  padding-right: 5px;
}
.dropdown-toggle::after {
  vertical-align: middle;
}
.header_icons > div {
  flex: 1;
}
.header_icons {
  display: flex;
  align-items: center;
}
.header_icons > span {
  flex: 1;
}
.header_icons.welcome_user > span {
  text-align: right;
}
.header_icons.welcome_user {
  align-items: flex-start;
}
.header_advertise img {
  height: auto;
  width: 250px;
}
.header_advertise {
  flex-direction: column;
}
.hader_top {
  flex-direction: column;
  align-items: center !important;
  justify-content: flex-end;
}
.header_logo {
  text-align: center;
  padding-top: 27px;
}
.header_logo img {
  height: 130px;
  object-fit: contain;
}
.icon_detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.action {
  margin-bottom: 10px;
}
.go_live_header {
  color: #ff2800;
  font-size: 18px;
  font-family: "Avalon_Bold";
  cursor: pointer;
  text-align: center !important;
}
@media (max-width: 545px) {
  .header_logo img {
    width: 85px;
    margin: 0 20px;
  }
  video {
    height: 230px;
  }
  .float_button:after {
    font-size: 16px;
  }
  .float_button {
    height: 70px;
    width: 70px;
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .float_button {
    height: 111px !important;
  }
  .header_icons.welcome_user {
    flex-direction: column;
    align-items: center;
  }
  .main_sec.custom_main_sec {
    order: 1;
  }
  .mobile_icon {
    order: 2;
  }
  .web_icon {
    order: 3;
  }
}
