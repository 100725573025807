.dropdown-item.active,
.dropdown-item:active {
  color: black;
  text-decoration: none;
  background-color: white;
}
.dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
