@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/ProximaNova-Regular-1.otf");
  font-weight: 500;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Bold.otf");
  font-weight: 600;
}

@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Extrabold.otf");
  font-weight: 700;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Black.otf");
  font-weight: 800;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Thin.otf");
  font-weight: 300;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Alt Light.otf");
  font-weight: 200;
}
@font-face {
  font-family: proxima-nova;
  src: url("../fonts/Proxima Nova Alt Thin.otf");
  font-weight: 100;
}
@font-face {
  font-family: mplus;
  src: url("../fonts/mplus-2p-thin.ttf");
}
@font-face {
  font-family: Avalon_Bold;
  src: url("../fonts/Avalon_Bold.ttf");
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: proxima-nova;
  background: #1b1b1b;
}
.main {
  height: 80vh;
}
.backgroud_image img {
  width: 100%;
  height: 100%;
  border: 12px solid transparent;
  border-image: url("../images/border-image.png") 20;
  border-image-width: 12px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.backgroud_image:after {
  position: absolute;
  content: "";
  background-image: url("../images/journal_double_background.png");
  top: 35px;
  right: -100px;
  width: 50%;
  z-index: 2;
  height: 103%;
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
.journal_sec.position-relative {
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nextButton {
  width: 40px !important;
  height: auto !important;
  position: absolute;
  bottom: 35px;
  padding: 0 !important;
  z-index: 99;
  left: 10px;
  /* transform: rotate(180deg); */
}
.nextButton.prev {
  right: 20px;
  left: inherit;
}
.position-relative.chef_image_sec {
  z-index: 999;
}
.chef_image_container {
  height: 80vh;
}
.chef_container > div {
  flex: 1;
}
.chef_image_sec img {
  /*width: 100%;*/
}
.chef_image_sec {
  border-right: 4px solid #86888940;
  border-radius: 2px;
}
.chef_image_sec > img {
  padding-left: 25px;
  padding-top: 25px;
  height: 80vh;
  object-fit: cover;
  object-position: top;
  border-radius: 30%;
  padding-bottom: 25px;
  padding-right: 15px;
}
.top_chef_details {
  top: 40px;
  left: 0;
  align-items: center;
  width: 100%;
  padding: 0 100px;
}
.grey_logo {
  flex: 1;
}
.chef_desc {
  flex: 2.5;
  text-align: center;
  margin-left: 5px;
  text-shadow: 0 0 0px #00000030;
}
.top_chef_details {
  top: 50px;
  align-items: center;
  margin: 0 100px;
  /*background-image: linear-gradient(to right, #ffffff , #f5f5f500);*/
  width: calc(100% - 200px);
  padding: 10px;
}
.grey_logo img {
  width: 180px !important;
}
.grey_logo {
  text-align: center;
  margin: 25px 0;
}
.chef_name {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 4px;
  font-family: Avalon_Bold;
}
.chef_designation {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 4px;
  font-family: Avalon_Bold;
  color: #5f5f5f;
}
.ref {
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 1px;
  font-family: Avalon_Bold;
  color: #5f5f5f;
}
.down_chef_links {
  bottom: 15%;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  background-color: black;
  justify-content: center;
  margin-left: 8px;
}
.down_chef_links div {
  text-decoration: underline;
  color: white;
  font-size: 18px;
}
.bottom_action {
  background-color: black;
  bottom: 30px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}
.heart_image img {
  width: 30px;
  object-fit: contain;
  margin-right: 20px;
}
.followers {
  color: white;
  font-weight: 600;
}
.journal_sec img {
  padding-left: 25px;
  padding-top: 25px;
  height: 65vh;
  object-fit: cover;
  object-position: top;
  padding-bottom: 25px;
  padding-right: 15px;
  padding: 0 15px 0 0;
}
.storysub2 {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}
.storyHeader {
  display: flex;
  justify-content: space-between;
  margin: 15px 25px 0;
}
.storyHeader > div > p:first-child {
  font-size: 22px !important;
  margin-bottom: 0;
}
.storysub2 input {
  margin-right: 5px;
}
p.headerJournal {
  text-align: right;
}
table.tableJournal {
  width: 100%;
  text-align: center;
}
.mainDivJournal {
  width: 100%;
  margin-right: 20px;
  margin-top: 25px;
}
.journal_sec.position-relative {
  align-items: baseline;
}
table.tableJournal th,
table.tableJournal td {
  padding-bottom: 50px;
}

.reviewHeader img {
  width: 80px;
  height: 80px;
  padding-right: 0;
  border-radius: 20px;
  object-fit: cover;
}
.reviewContainer {
  margin-top: 15px;
  width: 100%;
}
p.titleReview {
  text-align: right;
  margin-right: 25px;
  text-transform: uppercase;
}
.reviewHeader {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 20px;
  margin-top: inherit;
}
.reviewHeader .bodyRiview {
  flex: 2.5;
}
.review_image {
  flex: 1;
}
.bodyRiview .name {
  text-decoration: underline;
}

.paginate_mystory {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 5px;
  right: 30px;
}
.paginate_mystory .active {
  color: black !important;
  /* padding: 10px; */
  background: #4a5568;
}
.paginate_mystory li {
  list-style-type: none;
  cursor: pointer;
  color: #4a5568;
  padding: 8px 12px;
  border-top: 1px solid #4a5568;
  border-left: 1px solid #4a5568;
  border-bottom: 1px solid #4a5568;
  font-weight: 400;
  font-size: 12px;
}
.paginate_mystory li:last-child {
  border-right: 1px solid #4a5568;
}

.mystory_option {
  /* width: 40%;
  margin: auto; */
  cursor: pointer;
}
.mystory_option:first-child {
  margin-right: 15px;
}
.mystory_option:last-child {
  margin-left: 15px;
}
table.tableJournal img {
  height: 80px;
  width: 80px;
  padding: 0;
  border-radius: 25px;
}
.fanJurnalMain {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fanJurnalMain p:last-child {
  margin-left: 10px;
}
tr.fanJurnalTable td {
  padding-bottom: 25px;
}
p.fanReviewTable_time {
  font-size: 12px;
  text-align: left;
}
.fanReviewTable_time_name P:first-child {
  margin-right: 10px;
}
.fanReviewTable_time_name {
  display: flex;
  align-items: center;
}
p.fanReviewTable_comment {
  text-align: left;
}
tr.fanReviewTable p {
  margin-bottom: 5px;
}
tr.fanReviewTable img {
  height: 90px;
  width: 90px;
  border-radius: 25px;
  padding-right: 0;
}
tr.fanReviewTable > td:nth-child(2) {
  max-width: 225px;
}
tr.fanReviewTable td {
  padding-bottom: 10px;
}
.fanReviewTable_hide input {
  margin-right: 5px;
  vertical-align: inherit;
}

.paggination_mystory {
  position: absolute;
  bottom: 35px;
  right: 25px;
}
.live_text_div {
  position: absolute;
  top: 70px;
  right: 70px;
}
.live_text_h1 {
  font-size: 22px !important;
  font-weight: 600 !important;
}
.golive_logo img {
  width: 60px;
}
.golive_logo {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 30px;
  display: flex;
  align-items: center;
}
.join_logo {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  right: 30px;
}
.join_logo img {
  width: 90px;
  object-fit: contain;
}
.join_logo p {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 0;
}
